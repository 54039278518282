import { connect } from 'react-redux'
import OnlineOrderForm from '../components/OnlineOrderForm'
import {
  changeCartView,
  closeCartPopover,
  closeErrorModal,
  goToCartPopover,
  removeProductFromCart
} from "../actions";

const mapStateToProps = (state) => ({
  fontColor: state.general_settings.font_color,
  formDisplayBanner: state.general_settings.display_banner,
  currencySymbol: state.general_settings.currency_symbol,
  formBanner: state.general_settings.banner,
  formType: state.general_settings.form_type,

  isCartView: state.form_state.isCartView,
  newItemAddedToCart: state.form_state.newItemAddedToCart,
  orderSubmitted: state.form_state.orderSubmitted,
  editMode: state.form_state.editMode,
  formClosed: state.form_state.closed,
  errorHTML: state.form_state.errorHTML,
  showErrorModal: state.form_state.showErrorModal,
  preview: state.form_state.preview,

  itemsInCart: state.cart.itemsInCart,
  cartProducts: state.cart.products,
  totalCost: state.cart.totalCost.toFixed(2),
})

const mapDispatchToProps = (dispatch) => ({
  closeErrorModal: () => dispatch(closeErrorModal()),
  changeCartView: (data) => dispatch(changeCartView(data)),
  goToCartPopover: () => dispatch(goToCartPopover()),
  closeCartPopover: () => dispatch(closeCartPopover()),
  removeProductFromCart: (product) => dispatch(removeProductFromCart(product))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineOrderForm)
