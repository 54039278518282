import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types'
import TimePeriod from './TimePeriod';
import {useState, useEffect} from 'react';
import update from 'immutability-helper';
import GsgButton from '../../shared/components/gsg_button';
import BarChartComponent from './shared/BarChartComponent';
import OrderTrend from './shared/LineTrend';
import Summary from './shared/Summary';
import PieChartComponent from './shared/PieChartComponent';
import LineTrend from './shared/LineTrend';

function Rental(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);
  const {customer_id, moduleKey, from, to, reference_date, date_format, refresh_date} = props;
  const {} = state;

  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: "rental", customer_id: customer_id, to: to, from: from, reference_date: reference_date, date_format: date_format})
  });

  const include_demos_options = [
    {label: "returned", value: "demo_trackers.returned_date"}, 
    {label: "issued", value: "demo_trackers.issued_date"}, 
  ]
  
  return (
    <div className="col h-100 d-flex flex-column w-100 background_white">
      <TimePeriod to={to} 
                  from={from} 
                  reference_date={reference_date}
                  date_format={date_format}
                  refresh_date={refresh_date}
                  moduleKey={moduleKey} 
                  customer_id={customer_id}
                  load_component_data={props.load_component_data} 
                  options_for_select={include_demos_options}/> 
      <Summary data={props.components.summary["data"]} isLoading={props.components.summary["isLoading"]}/>
      <b className='main_header'>ANALYZE</b>
      <div className='row'>
        <BarChartComponent 
                title="TOP RENTAL SETS" 
                chart_data={props.components.top_rental_sets["chart_data"]}
                isLoading={props.components.top_rental_sets["isLoading"]}
                sort_options={[]}
                filters={props.components.top_rental_sets["filters"]}
                componentKey="top_rental_sets" 
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                />
        <BarChartComponent 
                title="TOP GENERAL RENTALS" 
                chart_data={props.components.top_general_items["chart_data"]}
                isLoading={props.components.top_general_items["isLoading"]}
                sort_options={[]} 
                filters={props.components.top_general_items["filters"]}
                componentKey="top_general_items" 
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                />
      </div>
      <div className='row'>
      <PieChartComponent title='SET FLEX' 
                      componentKey='set_type' 
                      data={props.components.set_type["chart_data"]}
                      isLoading={props.components.set_type["isLoading"]}
                      />
      <PieChartComponent title='RIGHT OR LEFT HAND' 
                    componentKey='r_l_hand'
                    data={props.components.r_l_hand["chart_data"]}
                      isLoading={props.components.r_l_hand["isLoading"]}
                    />
      </div>
    </div>
  )
}
export default Rental
