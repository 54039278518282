import React, { Component, Fragment } from "react";
import FroalaEditor from 'react-froala-wysiwyg'; 
import { Rnd } from 'react-rnd';
import UploadFile from '../../../shared/components/upload_file';

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
// import "froala-editor/js/plugins/fullscreen.min.js"

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
// import "froala-editor/css/plugins/fullscreen.min.css";
import {useState, useEffect} from 'react';
import update from 'immutability-helper';

import _ from "lodash"

function CollagePageTile(props) {
  
  const initialState = {
    selectedItemIndex: null,
    froalaItemIndex: null,
    froalaStagingContent: "",
    items: _.cloneDeep(props.options.items),
    href: false
    }
  
  const [state, setState] = useState(initialState);
  
  if(props.collageTileSave)
    useEffect(() => {
      props.collageTileSave.current = save
    }, [props.colId, state.items])

  const duplicateItem = () => {
    let items = [...state.items]
    let new_item = _.cloneDeep(items[state.selectedItemIndex])
    new_item.position.top += 20
    new_item.position.left += 20
    new_item.order = Math.max(state.items.map((i) => i.order )) || 0,

    setState(update(state, {
      items: {
        $push : [new_item]
      },
      selectedItemIndex: { $set :  items.length}
    }))
  };

  const addText = () => {
    let new_item = 
      {
        type: "text",
        content: "Double Click to Edit",
        order: (Math.max(state.items.map((i) => i.order )) || 0)+1,
        position: {
          top: 50,
          left: 50
        },
        size: {
          width: 150,
          height: 50,
        },
      }
    let items = [...state.items]
    
    setState(update(state, {
      items: {
        $push : [new_item]
      },
      selectedItemIndex: { $set :  items.length},
      froalaStagingContent: { $set : "Double Click to Edit" },
      froalaItemIndex: { $set : items.length },
    }))
    // hide modal footer
    document.getElementById("gsc_modal_footer").classList.add("d-none")
    document.getElementById("tileTypeTitleId").classList.add("d-none")
  };

  const addRectangle = () => {    
    let new_item = 
    {
      type: "rectangle",
      content: "",
      order: ( Math.max(state.items.map((i) => i.order )) || 0) + 1,
      position: {
        top: 10,
        left: 10
      },
      size: {
        width: 130,
        height: (props.column.height - 16)/4
      },
      style: { backgroundColor: "#FFFFFF", opacity: 1, borderStyle: 'solid' }
    }

    setState(update(state, {
      items: {
        $push : [new_item]
      }
    }))
  };

  const sendToBack = () => {
    setState(update(state, {
      items: {
        [state.selectedItemIndex]:
          {
            order: { $set : (Math.min(state.items.map((i) => i.order )) || 0) - 1 }
          }
            }
          }
      ))
  };

  const sendToFront = () => {
    setState(update(state, {
      items: {
        [state.selectedItemIndex]:
          {
            order: { $set : (Math.max(state.items.map((i) => i.order )) || 0) + 1 }
          }
            }
          }
      ))
  };

  const deleteItem = () => {
    let items = [...state.items]
    items.splice(state.selectedItemIndex, 1)

    setState(update(state, {
      selectedItemIndex: { $set : null },
      items: { $set : items }
          }
      ))
  };
  
  const setSelectedIndex = (i) => {
    setState(update(state, {
      selectedItemIndex: { $set : i }
          }
      ))
  }

  const save = () => {
    props.updateCollageItems(props.colId,  state.items)
  };

  const reset = () => {
    setState(update(state, {
      items: { $set : _.cloneDeep(props.options.items) || [] }
          }
      ))
  };

  const setFroalaStagingText = (content) => {
    setState(update(state, {
      froalaStagingContent: { $set : content }
          }
      ))
  }

  const setText = () => {
    setState(update(state, {
      items: { [state.froalaItemIndex]: {content: {$set : state.froalaStagingContent }}},
      froalaStagingContent: { $set : "" },
      froalaItemIndex: { $set : null },
          }
      ))
    // show modal footer
    document.getElementById("gsc_modal_footer").classList.remove("d-none")
    document.getElementById("tileTypeTitleId").classList.remove("d-none")
  }

  const setItemCoordinates = (e,d) =>{
    setState(update(state, {
      items: { [state.selectedItemIndex]: {position: {$set : { top: d.y, left: d.x} }}},
          }
      ))
  }

  const onResize = (e, direction, ref, delta, position) => {
    setState(update(state, {
      items: { [state.selectedItemIndex]: {size: {$set : {width: ref.style.width, height: ref.style.height} }}},
          }
      ))
  }

  const clearFroala = () => {
    setState(update(state, {
      froalaItemIndex: {$set : null},
          }
      ))
    // show modal footer
    document.getElementById("gsc_modal_footer").classList.remove("d-none")
    document.getElementById("tileTypeTitleId").classList.remove("d-none")
  }
  
  // to prevent drag and drop in preview mode and to improve scrolling on mobile devices
  const setHref = () => {
    setState(update(state, {
        href: {$set : true},
      }
    ))
  }

  const setItemProperty = (property, value) => {
    let items = [...state.items]
    items[state.selectedItemIndex].style[property] = value

    setState(update(state, {
      items: {$set : items }},
      ))
  }

  const uploadCollagePhoto = (data) => {
    const dataURL = `/customers/${props.general_settings.customer_id}/pages/upload_image`
    if(data)
    {
      document.getElementById("upload_spinner").classList.remove("d-none");
      return new Promise((resolve, reject) => {
        fetch(
          dataURL,
          {
            method: 'POST',
            body: data,
          }
        )
        .then(response => Promise.all([ response, response.json() ]))
        .then(([ response, json ]) => {
          if (response.status === 200 && _.isEmpty(json.errorMessage)) {
            resolve(json)
          } else {
            reject(json.errorMessage, json)
          }
        })
        .catch(() => {
          reject()
        })
      }).then(response => {
          let img =  {
            type: "image",
            content: "",
            order: Math.max(state.items.map((i) => i.order )) || 0,
            position: {
              top: 1,
              left: 1
            },
            size: {
              width: 300,
              height: 300
            },
            attachment_id: response["id"],
            url: response["url"],
            style: { opacity: 1}
          }

          setState(update(state, {
            items: {
              $push : [img]
            }
          }))
        })
      }
  }

  const enableFroalaEditor = (index) => {
    let items = [...state.items]

    setState(update(state, {
      froalaStagingContent: { $set : items[index].content },
      froalaItemIndex: { $set : index },
          }
      ))
    // hide modal footer
    document.getElementById("gsc_modal_footer").classList.add("d-none")
    document.getElementById("tileTypeTitleId").classList.add("d-none")
  }

  const addPhotoFromDesktop = () => {
    document.getElementById("add_photo_input").click()
  }
  
  
  let container_width = props.general_settings.container_width.slice(0, props.general_settings.container_width.length - 2) - 75
  let container_height = (props.displayActions && props.column ? props.column.height - 16 : '100%')
  
  if(props.block.layout == "2") 
    container_width = container_width / 2

  if(props.block.layout == "3")
    if(props.column.position == "left") 
      container_width = container_width / 3
    else
      container_width = container_width / 1.5

  if(props.block.layout == "4")
    if(props.column.position == "left") 
      container_width = container_width / 1.5
    else
      container_width = container_width / 3
  
    let config_froala = {
      placeholderText: 'Edit Your Content Here!',
      charCounterCount: false,
      key: 'aLF3c1C9C7A5D3F2H3C-7SLJCKHXOSLMc2YGSGe1ZXHSa1CgC3E3G3B2B7A4B3F4E2B2==',
      inlineMode: false,
      minHeight: 130,
      maxHeight: 1000,
      height: container_height,
      width: container_width,
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting', '|', 'emoticons', 'fontAwesome', 'specialCharacters', '-', 'paragraphFormat', 'lineHeight', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '|', 'insertLink', 'insertImage', 'insertFile', 'insertTable', '-', 'insertHR', 'getPDF', 'print', 'help', 'fullscreen', '|', 'undo', 'redo'],
      toolbarSticky: false,
      colorsStep: 14,
      useClasses: false,
      lineHeights: {
        Default: '',
        Single: '1',
        '1.15': '1.15',
        '1.20': '1.20',
        '1.25': '1.25',
        '1.3': '1.30',
        '1.4': '1.40',
        '1.5': '1.5',
        Double: '2',
        Tripe: '3'
      },
      zIndex: 9990,
      fileUploadParam: 'file_param',
      fileUploadURL: '/froala_upload_files',
      imageUploadParam: 'file_param',
      imageUploadURL: '/froala_upload_files',
      imageUploadMethod: 'POST',
      fileUploadMethod: 'POST'
    }
    return (
      <div className={ "collage_page_tile p-0 w-100" + (props.displayActions ? "" : " view_only") } style={{pointerEvents: (state.href || props.displayActions) ? "auto" : "none"}} onMouseDown={(e)=> (state.href && !props.displayActions) ? e.preventDefault() : ""}>
        { props.displayActions &&
            <div className="row controls">
              { state.froalaItemIndex == null && <div className="col-12 pt-1">
                  <a className="btn gems_custom_button save pull-right d-none" id={`save_collage_page_${props.colId}`} onClick={ () => {save()} }>Save</a>
                  {/* <a className="btn gems_custom_button manage_action pull-right mx-2" onClick={ () => {reset()} }>Revert All</a> */}
                </div>
              }
              { state.froalaItemIndex == null && 
                <div className="col-12">
                  <div style={ { color: "darkgrey", marginBottom: "12px" }}>
                      Actions
                  </div>
                  <div className="actions p-2 border">
                    <a
                      className="col btn gems_custom_button new_action"
                      onClick={() => {addText()}}
                    >
                      <i className="fa fa-plus-circle mx-2"></i>
                      Add Text
                    </a>

                    <a
                      className="col btn gems_custom_button new_action"
                      onClick={addRectangle}
                    >
                      <i className="fa fa-plus-circle mx-2"></i>
                      Add Rectangle
                    </a> 
                    <a
                      className="col btn gems_custom_button new_action"
                      onClick={addPhotoFromDesktop}
                    >
                      <i className="fa fa-plus-circle mx-2"></i>
                      Upload Photo
                    </a>
                    <span className="spacing"></span>                
                    <a
                      className={ `btn col gems_custom_button manage_action ${state.selectedItemIndex == null ? 'disabled' : ''}` }
                      onClick={  state.selectedItemIndex != null ? sendToFront : null }
                    >
                      <i className="fa fa-square mx-2"></i>
                      Send to Front
                    </a>
                    <a
                      className={ `btn col gems_custom_button manage_action ${state.selectedItemIndex == null ? 'disabled' : ''}` }
                      onClick={  state.selectedItemIndex != null ? sendToBack : null}
                    >
                      <i className="fa fa-square-o mx-2"></i>
                      Send to Back
                    </a>
                    <a
                      className={ `btn col gems_custom_button manage_action ${state.selectedItemIndex == null ? 'disabled' : ''}` }
                      onClick={  state.selectedItemIndex != null ? duplicateItem : null}
                    >
                      <i className="fa fa-clone mx-2"></i>
                      Duplicate
                    </a>
                    <a
                        className={ `btn col gems_custom_button manage_action delete ${state.selectedItemIndex == null ? 'disabled' : ''}` }
                        onClick={  state.selectedItemIndex != null ?  deleteItem : null }
                      >
                        <i className="fa fa-trash mx-2"></i>
                        Delete Item
                    </a>
                  </div>
                  <UploadFile uploadFile={uploadCollagePhoto} 
                            display={'none'} 
                            customer_id={props.customer_id} 
                            item_id={"add_photo_collage"} 
                            file_id={"add_photo_input"} 
                  />
                  <div style={ { minHeight: 75}}>
                  {  
                    state.selectedItemIndex != null && (state.items[state.selectedItemIndex].type == "rectangle" || state.items[state.selectedItemIndex].type == "image") && <Fragment>
                      <div style={ { color: "darkgrey" }}>
                          Properties
                      </div>
                      <div className="actions mb-3">
                        <div className="property">
                          <label className="col-form-label">Opacity</label>
                          <input type="range" id="background_opacity" name="background_opacity" min="0" max="1" step='0.1' onChange={ (event) =>{ setItemProperty ("opacity", event.target.value) }} value = { (state.items[state.selectedItemIndex].style || {opacity: '1'}).opacity } />
                        </div>
                        {state.items[state.selectedItemIndex].type == "rectangle" && <>
                        <div className="property">
                          <label className="col-form-label">Background Color</label>
                          <input type="color" value = { (state.items[state.selectedItemIndex].style || {backgroundColor: "#FFFFFF"}).backgroundColor } onChange={ (event) =>{ setItemProperty ("backgroundColor", event.target.value) }}></input> 
                        </div>
                        <div className="property">
                        <label className="col-form-label">Border Color</label> 
                        <input type="color" value = { (state.items[state.selectedItemIndex].style || {borderColor: "#FFFFFF"}).borderColor } onChange={ (event) => setItemProperty ("borderColor", event.target.value) }></input> 
                        </div>
                        <div className="property">
                        <label className="col-form-label">Border</label>
                          <select className="form-select" onChange={ (event) => setItemProperty ("borderStyle", event.target.value) } value = { (state.items[state.selectedItemIndex].style || {borderStyle: "solid"}).borderStyle }>
                            <option value="none">None</option>
                            <option value="solid">Solid</option>
                            <option value="dotted">Dotted</option>
                            <option value="dashed">Dashed</option>
                            <option value="double">Double</option>
                          </select> 
                        </div>
                        <div className="property ">
                          <label className="col-form-label">Border Width</label>
                          <select type="select" onChange={ (event) => setItemProperty ("borderWidth", event.target.value) } className="form-select" value = { (state.items[state.selectedItemIndex].style || {borderWidth: "1px"}).borderWidth}>
                            <option value="1px">1</option>
                            <option value="2px">2</option>
                            <option value="3px">3</option>
                            <option value="4px">4</option>
                            <option value="5px">5</option>
                          </select>
                        </div>
                        <div className="property">
                        <label className="col-form-label">Border Radius</label>
                          <select type="select" className="form-select" onChange={ (event) => setItemProperty ("borderRadius", event.target.value) } value = { (state.items[state.selectedItemIndex].style || {borderRadius: "0%"}).borderRadius}>
                            <option value="0%">0%</option>
                            <option value="5%">5%</option>
                            <option value="15%">15%</option>
                            <option value="25%">30%</option>
                            <option value="50%">50%</option>
                          </select> 
                        </div></>}
                      </div>
                    </Fragment>
                  }
                  </div>
                </div>
              }
            { state.froalaItemIndex != null &&
              <div className="col-12 collage_canvas p-0" >
                <div className="row m-0">
                  <a></a> {/*NOT TO BE DELETED - image link*/}
                  <div className="col-12 mb-3" style={{minHeight: 300}}>
                    <FroalaEditor tag='textarea'
                                  config={{...config_froala}}
                                  model={state.froalaStagingContent}
                                  onModelChange={setFroalaStagingText}/>
                  </div>
                </div>
                <div className="row pb-2 me-0">
                  <div className="col-12">
                    <button className="btn gems_custom_button save pull-right gems_orange_button" onClick={ setText }>Apply Text Change</button>
                    <button className="btn gems_custom_button manage_action pull-left mx-2" onClick={ clearFroala }>Cancel Text Change</button>
                  </div>
                </div>
              </div>
            }
          </div>
        }
        {state.froalaItemIndex == null && <div
            className={ `row m-0 bg-white overflow-hidden collage_canvas ${props.displayActions ? "edit_mode" : ''}` }
            style={{
              border: (props.displayActions ? 'solid' : ''),
              height: container_height,
              width: container_width, position: 'relative'}}
          >
            {
              (props.displayActions ? state.items : props.options.items).map((item, i) => {
                
                if(item.content.includes("href") && !state.href)
                  setHref()
                
                return <Rnd   key={`${item.type}_${i}`}
                                size={{ width: item.size.width,  height: item.type == 'image' ? 'auto' : item.size.height}}
                                position={{ x: item.position.left || 0, y: item.position.top || 0 }}
                                onDragStop={ setItemCoordinates }
                                dragAxis={ state.selectedItemIndex == i && props.displayActions ? "both" : "none"}
                                enableResizing = { state.selectedItemIndex == i  && props.displayActions }
                                onResizeStop={ onResize }
                                bounds = "parent"
                                style = { state.selectedItemIndex == i  && props.displayActions ? {zIndex: item.order + 1000} : {zIndex: item.order + 1000, cursor: "initial" }}
                                >
                      { 
                            (() => { switch(item.type) {
                              case 'text':  
                                return <div className={ `collage_text ${ state.selectedItemIndex == i ? 'selected' : ''}` }
                                          onClick={ (e) => { e.detail == 1 ? setSelectedIndex(i) : enableFroalaEditor(i) }}
                                          dangerouslySetInnerHTML={{__html: item.content}}
                                        >
                                      </div>
                              case 'rectangle':
                                return <div className={ ` collage_rectangle ${ state.selectedItemIndex == i ? 'selected' : ''}` }
                                              onClick={ () => setSelectedIndex(i) }
                                              style = { { ...item.style }}
                                              >
                                            { item.content }
                                      </div>
                              case 'image':
                                return <img src={ item.url }
                                              className={ `collage_image ${ state.selectedItemIndex == i ? 'selected' : ''}` }
                                              onClick={ () => setSelectedIndex(i) }
                                              style= {{ ...item.style ,
                                                    maxHeight: `${container_height}px`,
                                                    maxWidth: `${container_width}px`}}
                                        />
                              default:
                                null
                            }
                      })()
                    }
                      </Rnd>
            })
          }
        </div>}
      </div>
    );
  }

export default CollagePageTile;
