import WebpackerReact from 'webpacker-react'

import Footer from 'src/stock_orders/components/Footer'
import Dashboard from 'src/stock_orders/components/Dashboard'
import Header from 'src/stock_orders/components/Header'
import Body from 'src/stock_orders/components/Body'
import PipedriveHeader from 'src/stock_orders/components/PipedriveHeader'
import PipedriveBody from 'src/stock_orders/components/PipedriveBody'
import CalendarBody from 'src/stock_orders/components/CalendarBody'
import PipedriveItem from 'src/stock_orders/components/PipedriveItem'
import DashboardAdvancedFilter from 'src/stock_orders/components/DashboardAdvancedFilter'
import StockOrdersRoot from 'src/stock_orders/containers/StockOrdersRoot'
import StockOrderModal from 'src/stock_orders/components/StockOrderModal'
import PlaceStockOrderModal from 'src/stock_orders/components/PlaceStockOrderModal'
import ShipDetailsStockOrderModal from 'src/stock_orders/components/ShipDetailsStockOrderModal'
import ReceiveStockOrderModal from 'src/stock_orders/components/ReceiveStockOrderModal'
import FinalizeStockOrderModal from 'src/stock_orders/components/FinalizeStockOrderModal'
import SplitStockOrderModal from 'src/stock_orders/components/SplitStockOrderModal'
import CancelStockOrderModal from 'src/stock_orders/components/CancelStockOrderModal'
import FilterCategoryModal from 'src/stock_orders/components/FilterCategoryModal'
import UploadFile from 'src/stock_orders/components/UploadFile'
import Year from 'src/stock_orders/components/Year'
import MultiWidgetsRoot from '../src/multi_widgets/components/containers/root'
import MasterRosterSpreadsheetUpdateRoot from '../src/member_roster_spreadsheet_update/containers/MasterRosterSpreadsheetUpdateRoot'
import ShopDashboardRoot from '../src/shop_dashboard/containers/ShopDashboardRoot'
import CommunicationCenterRoot from '../src/communications/communication_center/containers/CommunicationCenterRoot'
import EmailCenterRoot from '../src/communications/email_center/containers/EmailCenterRoot'
import TextMessageCenterRoot from '../src/communications/text_message_center/containers/TextMessageCenterRoot'
import ListViewOptionalFieldsModal from 'src/stock_orders/components/ListViewOptionalFieldsModal'
import CallawayConnectMatchCategoriesRoot from '../src/integrations/callaway_connect/containers/CallawayConnectMatchCategoriesRoot'
import CallawayConnectMatchFieldsRoot from '../src/integrations/callaway_connect/containers/CallawayConnectMatchFieldsRoot'
import OnlineOrderFormRoot from '../src/online_forms/containers/OnlineOrderFormRoot'
import ProfileSetupRoot from 'src/profile_setup/containers/ProfileSetupRoot'


WebpackerReact.setup({CommunicationCenterRoot})
WebpackerReact.setup({EmailCenterRoot})
WebpackerReact.setup({TextMessageCenterRoot})
WebpackerReact.setup({CallawayConnectMatchCategoriesRoot})
WebpackerReact.setup({CallawayConnectMatchFieldsRoot})

WebpackerReact.setup({ Dashboard })
WebpackerReact.setup({ Header })
WebpackerReact.setup({ Footer })
WebpackerReact.setup({ Body })
WebpackerReact.setup({ PipedriveHeader })
WebpackerReact.setup({ PipedriveBody })
WebpackerReact.setup({ CalendarBody })
WebpackerReact.setup({ PipedriveItem })
WebpackerReact.setup({ DashboardAdvancedFilter })
WebpackerReact.setup({ StockOrdersRoot })
WebpackerReact.setup({ StockOrderModal })
WebpackerReact.setup({ PlaceStockOrderModal })
WebpackerReact.setup({ ShipDetailsStockOrderModal })
WebpackerReact.setup({ ReceiveStockOrderModal })
WebpackerReact.setup({ FinalizeStockOrderModal })
WebpackerReact.setup({ SplitStockOrderModal })
WebpackerReact.setup({ CancelStockOrderModal })
WebpackerReact.setup({ FilterCategoryModal })
WebpackerReact.setup({ UploadFile })
WebpackerReact.setup({ Year })
WebpackerReact.setup({ MultiWidgetsRoot })
WebpackerReact.setup({ MasterRosterSpreadsheetUpdateRoot })
WebpackerReact.setup({ ShopDashboardRoot })
WebpackerReact.setup(ListViewOptionalFieldsModal)
WebpackerReact.setup({OnlineOrderFormRoot})
WebpackerReact.setup({ ProfileSetupRoot })





