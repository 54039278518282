import { showErrorNotification, callAPI } from "shared/helpers";

export const ActionTypes = {
	FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST:
		"FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST",
	QUICK_SEARCH: "QUICK_SEARCH",
	ENABLE_DISABLE_AUTOMATED_TEMPLATE: "ENABLE_DISABLE_AUTOMATED_TEMPLATE",
	UPDATE_DEMO_REMINDER_TEMPLATE_DAYS: "UPDATE_DEMO_REMINDER_TEMPLATE_DAYS",
	UPDATE_TEMPLATE_ATTRIBUTE: "UPDATE_TEMPLATE_ATTRIBUTE",
	CLONE_DEFAULT_TEMPLATE: "CLONE_DEFAULT_TEMPLATE",
	DELETE_TEMPLATE: "DELETE_TEMPLATE",
	RESET_TEMPLATES: "RESET_TEMPLATES",
	COMMUNICATION_HISTORY_PAGINATE: "COMMUNICATION_HISTORY_PAGINATE",
	PAGINATE_UNSENT_TEXT_MESSAGES: "PAGINATE_UNSENT_TEXT_MESSAGES",
	CLOSE_CUSTOM_TEMPLATE_MODAL: "CLOSE_CUSTOM_TEMPLATE_MODAL",
	OPEN_CUSTOM_TEMPLATE_MODAL: "OPEN_CUSTOM_TEMPLATE_MODAL",
	OPEN_COMMUNICATION_TEMPLATE_MODAL: "OPEN_COMMUNICATION_TEMPLATE_MODAL",
	UNSCHEDULE_TEXT_MESSAGE: "UNSCHEDULE_TEXT_MESSAGE",
	DELETE_DRAFT_TEXT_MESSAGE: "DELETE_DRAFT_TEXT_MESSAGE",
	CLOSE_COMMUNICATION_TEMPLATE_MODAL: "CLOSE_COMMUNICATION_TEMPLATE_MODAL",
	PAUSE_SCHEDULED_TEXT_MESSAGE: "PAUSE_SCHEDULED_TEXT_MESSAGE",
	RESUME_SCHEDULED_TEXT_MESSAGE: "RESUME_SCHEDULED_TEXT_MESSAGE",
};

export const fetchTextMessageCenterData = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/text_message_center";
	const params = { customer_id: state.dashboard.customer_id };

	callAPI(dataURL, "GET", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const changeQuickSearchValue = (value) => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.QUICK_SEARCH,
		data: { quick_search_value: value },
	});
};

export const changeTemplateEnableValue =
	(template_details) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/text_message_center/update_notification";
		const params = {
			customer_id: state.dashboard.customer_id,
			category_type: template_details.template_key,
			is_enabled: template_details.enabled ? "true" : "false",
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.ENABLE_DISABLE_AUTOMATED_TEMPLATE,
					data: {
						template_category: template_details.template_category,
						template_key: template_details.template_key,
						enabled: template_details.enabled,
					},
				});
			})
			.catch(() => {
				showErrorNotification("Error while updating data!");
			});
	};

export const changeTemplateDemoRemindersDaysValue =
	(template_details) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/settings/update_demo_reminder_text_message_days";
		const params = {
			customer_id: state.dashboard.customer_id,
			type: template_details.template_key,
			days: template_details.days,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.UPDATE_DEMO_REMINDER_TEMPLATE_DAYS,
					data: {
						template_category: template_details.template_category,
						template_key: template_details.template_key,
						days: template_details.days,
					},
				});
			})
			.catch(() => {
				showErrorNotification("Error while updating data!");
			});
	};

export const changeTemplateAttributeValue =
	(template_details) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/settings/update_template_attribute";
		const params = {
			customer_id: state.dashboard.customer_id,
			type: template_details.template_key,
			notification_channel: "sms",
			value: template_details.value,
			attribute_type: template_details.attribute_type,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.UPDATE_TEMPLATE_ATTRIBUTE,
					data: {
						template_category: template_details.template_category,
						template_key: template_details.template_key,
						value: template_details.value,
						attribute_type: template_details.attribute_type,
					},
				});
			})
			.catch(() => {
				showErrorNotification("Error while updating data!");
			});
	};

export const cloneTemplate =
	(default_template_id, template_type) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/text_message_center/clone_default_template";
		const params = {
			customer_id: state.dashboard.customer_id,
			default_template_id: default_template_id,
			template_type: template_type,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.CLONE_DEFAULT_TEMPLATE,
					data: response,
				});
			})
			.catch(() => {
				showErrorNotification("Error while fetching data!");
			});
	};

export const deleteTemplate = (template_id) => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/text_message_center/delete_template";
	const params = {
		customer_id: state.dashboard.customer_id,
		id: template_id,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.DELETE_TEMPLATE,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const resetTemplates = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/text_message_center/reset_templates";
	const params = {
		customer_id: state.dashboard.customer_id,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.RESET_TEMPLATES,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const paginateCommunicationHistory = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/text_message_center/paginate_communication_history";

	const params = {
		customer_id: state.dashboard.customer_id,
		page: state.sent_text_messages_history.page + 1,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.COMMUNICATION_HISTORY_PAGINATE,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const paginateUnsentTextMessages = () => (dispatch, getState) => {
	const state = getState();
	const dataURL = "/api/web/text_message_center/paginate_unsent_text_messages";

	const params = {
		customer_id: state.dashboard.customer_id,
		page: state.scheduled_text_messages.page + 1,
	};

	callAPI(dataURL, "POST", params, new Headers({ Accept: "application/json" }))
		.then((response) => {
			dispatch({
				type: ActionTypes.PAGINATE_UNSENT_TEXT_MESSAGES,
				data: response,
			});
		})
		.catch(() => {
			showErrorNotification("Error while fetching data!");
		});
};

export const openCustomTemplateModal = (data) => (dispatch, getState) => {
	const state = getState();
	var selected_template = null;

	if (data.template_type == "default")
		selected_template =
			state.custom_templates.default_text_message_templates.filter(
				(t) => t.id == data.template_id
			)[0];
	else
		selected_template =
			state.custom_templates.custom_text_message_templates.filter(
				(t) => t.id == data.template_id
			)[0];

	dispatch({
		type: ActionTypes.OPEN_CUSTOM_TEMPLATE_MODAL,
		data: {
			template: selected_template,
		},
	});
};

export const closeCustomTemplateModal = () => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CLOSE_CUSTOM_TEMPLATE_MODAL,
	});
};

export const openCommunicationTemplateModal =
	(data, text_message_type) => (dispatch, getState) => {
		const state = getState();
		const selected_template =
			text_message_type == "sent_text_message"
				? state.sent_text_messages_history.text_messages.filter(
						(t) => t.id == data
				  )[0]
				: state.scheduled_text_messages.text_messages.filter(
						(t) => t.id == data
				  )[0];

		dispatch({
			type: ActionTypes.OPEN_COMMUNICATION_TEMPLATE_MODAL,
			data: {
				template: selected_template,
			},
		});
	};

export const unscheduleTextMessage =
	(communication_history_id) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/text_message_center/unschedule_text_message";
		const params = {
			customer_id: state.dashboard.customer_id,
			communication_history_id: communication_history_id,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.UNSCHEDULE_TEXT_MESSAGE,
					data: response,
				});
			})
			.catch(() => {
				showErrorNotification("Error while fetching data!");
			});
	};

export const deleteDraftTextMessage =
	(communication_history_id) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/text_message_center/delete_draft_text_message";
		const params = {
			customer_id: state.dashboard.customer_id,
			communication_history_id: communication_history_id,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.DELETE_DRAFT_TEXT_MESSAGE,
					data: response,
				});
			})
			.catch(() => {
				showErrorNotification("Error while fetching data!");
			});
	};

export const pauseScheduledTextMessage =
	(communication_history_id) => (dispatch, getState) => {
		const state = getState();
		const dataURL = "/api/web/text_message_center/pause_scheduled_text_message";
		const params = {
			customer_id: state.dashboard.customer_id,
			communication_history_id: communication_history_id,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.PAUSE_SCHEDULED_TEXT_MESSAGE,
					data: response,
				});
			})
			.catch(() => {
				showErrorNotification("Error while fetching data!");
			});
	};

export const resumeScheduledTextMessage =
	(communication_history_id) => (dispatch, getState) => {
		const state = getState();
		const dataURL =
			"/api/web/text_message_center/resume_scheduled_text_message";
		const params = {
			customer_id: state.dashboard.customer_id,
			communication_history_id: communication_history_id,
		};

		callAPI(
			dataURL,
			"POST",
			params,
			new Headers({ Accept: "application/json" })
		)
			.then((response) => {
				dispatch({
					type: ActionTypes.RESUME_SCHEDULED_TEXT_MESSAGE,
					data: response,
				});
			})
			.catch(() => {
				showErrorNotification("Error while fetching data!");
			});
	};

export const closeCommunicationTemplateModal = () => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CLOSE_COMMUNICATION_TEMPLATE_MODAL,
	});
};
