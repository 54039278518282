import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import { Nav } from 'react-bootstrap'

const Logout = ({ general_settings, preview }) => {
  return (
      <Nav.Link className='p-0 tabs_container align-self-center' style={{width: "3%"}}>
        <div className='new-tab menu_tab d-block d-md-none' style={{backgroundColor: general_settings.color_schema}}>
          <a className='section-title nav-link text-center'
            role='tab'
            aria-selected='false'
            id='player-activity-tabs-new-tab'
            onClick={() => { general_settings.member_authenticated ? (window.location.search += '&logout=true') : null }}
            style = {{ color: getComplementaryColor((general_settings.color_schema || "#FFFFFF")), fontSize: "18px"}}
            >
            Logout
          </a>
        </div>
  
        <div class="dropdown d-none d-md-block float-end pe-2">
          <a class="" type="button" id="dropdownMenuGolfShopCentral" data-bs-toggle="dropdown" aria-expanded="true" style={{backgroundColor: general_settings.color_schema}}>
            <i className="fa fa-caret-down icon_dropdown_search fs-3" style={{color: getComplementaryColor((general_settings.color_schema || "#FFFFFF"))  }}/>
          </a>
          <ul class="dropdown-menu dropdown-menu-end m-0" aria-labelledby="dropdownMenuGolfShopCentral" data-popper-placement="bottom-start" style={{ backgroundColor: general_settings.color_schema, color: getComplementaryColor((general_settings.color_schema || "#FFFFFF")), top: "105%", fontSize: "18px", border: `1px solid ${getComplementaryColor((general_settings.color_schema || "#FFFFFF"))}` }}>
            <li>
              <a className={`section-title dropdown-item fs-6 text-center new-tab menu_tab ${preview ? 'disabled' : ''}`}
                role='tab'
                aria-selected='false'
                id='player-activity-tabs-new-tab'
                style={ { color: getComplementaryColor((general_settings.color_schema || "#FFFFFF")) }}
                onClick={() => { general_settings.member_authenticated ? (window.location.search += '&logout=true') : null }}
                >
                Logout
              </a>
            </li>
          </ul>
        </div>
  
      </Nav.Link>
  )
}

Logout.propTypes = {
  general_settings: PropTypes.object
}

export default connect()(Logout)
