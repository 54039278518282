import React from 'react'
import {useState, useEffect} from 'react';
import BarChartComponent from './shared/BarChartComponent';
import TimePeriod from './TimePeriod';
import Summary from './shared/Summary';
import VendorDeliveryPerformance from './stock_order/VendorDeliveryPerformance';
function StockOrder(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);
  const {customer_id, moduleKey, from, to, reference_date, date_format, refresh_date, used_category_ids} = props;
  const {} = state;
  
  const category_analyzer_sort_options = [
    {label: "Size", value: "size"}
  ]

  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: "stock_order", customer_id: customer_id, to: to, from: from, reference_date: reference_date, date_format: date_format})
  });
  const top_vendors_filter_options = props.categories_list_for_filter

  const include_orders_options = [
    {label: "start shipped", value: "stock_orders.start_date"}, 
    {label: "end shippped", value: "stock_orders.end_date"},
    {label: "expected shipped", value: "stock_orders.expected_ship_date"},
    {label: "expected delivered", value: "stock_orders.expected_delivery_date"},
    {label: "placed", value: "stock_orders.ordered_date"},
    {label: "delivered", value: "stock_orders.delivered_date"},
    {label: "finalized", value: "stock_orders.finalized_date"},
  ]



  const top_vendors_categories_sort_options = [
    {label: "Total Wholesale", value: "total_wholesale"},
    {label: "Total Orders", value: "total_orders"},
    {label: "Total Quantity of Items", value: "total_items"}]

  return (
    <div className="col h-100 d-flex flex-column w-100 background_white">
      <TimePeriod to={to} 
                  from={from} 
                  reference_date={reference_date}
                  date_format={date_format}
                  refresh_date={refresh_date}
                  moduleKey={moduleKey} 
                  customer_id={customer_id}
                  load_component_data={props.load_component_data} 
                  options_for_select={include_orders_options}/> 
      <Summary data={props.components.summary["data"]} isLoading={props.components.summary["isLoading"]}/>
      <b className='main_header'>ANALYZE</b>
      <div className='row'>
        <BarChartComponent 
                title="TOP VENDORS" 
                chart_data={props.components.top_vendors["chart_data"]} 
                sort_options={top_vendors_categories_sort_options}
                sort_options_for_filter = {top_vendors_filter_options}
                componentKey="top_vendors"
                sortTitle="Sort By"
                moduleKey={moduleKey}
                used_category_ids={used_category_ids}
                filters={props.components.top_vendors["filters"]}
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                isLoading={props.components.top_vendors["isLoading"]}
                />
        <BarChartComponent 
                title="TOP CATEGORIES" 
                chart_data={props.components.top_categories["chart_data"]} 
                sort_options={top_vendors_categories_sort_options} 
                componentKey="top_categories"
                sortTitle="Sort By"
                moduleKey={moduleKey}
                load_component_data= {props.load_component_data}
                filters={props.components.top_categories["filters"]}
                customer_id= {customer_id}
                isLoading={props.components.top_categories["isLoading"]}
                />
        </div>
        <div className='row'> 
          <VendorDeliveryPerformance 
          data={props.components.vendor_delivery_performance} 
          load_component_data={props.load_component_data} 
          isLoading={props.components.vendor_delivery_performance["isLoading"]}
          componentKey="vendor_delivery_performance"
          moduleKey={moduleKey}
          customer_id= {customer_id}
          />
          <BarChartComponent 
            title="CATEGORY ANALYZER" 
            chart_data={props.components.category_analyzer["chart_data"]} 
            vendors_options = {props.vendors_list}
            categories_options = {props.categories_list_options}
            sort_options = {props.categories_custom_fields}
            componentKey="category_analyzer"
            sortTitle="Custom field"
            moduleKey={moduleKey}
            filters={props.components.category_analyzer["filters"]}
            load_component_data= {props.load_component_data}
            customer_id= {customer_id}
            isLoading={props.components.category_analyzer["isLoading"]}
            />
      </div>
    </div>
  )
}
export default StockOrder
